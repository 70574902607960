import { initializeApp } from "firebase/app";
// import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBwiMA4HScwhYjDRMlcoGzmjKofnuWlXiY",
  authDomain: "republish-react.firebaseapp.com",
  databaseURL: "https://republish-react-default-rtdb.firebaseio.com",
  projectId: "republish-react",
  storageBucket: "republish-react.appspot.com",
  messagingSenderId: "249306848505",
  appId: "1:249306848505:web:f1fc4666522206c9de5628",
  measurementId: "G-H87KB5VR5X"
};

const app = initializeApp(firebaseConfig);
// export const db = getDatabase(app);
export const db = getFirestore(app);