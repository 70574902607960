let routes = [
	{
		path: '/boxspring',
		layout: 'Boxspring',
	},
	{
		path: '/teddydekbed',
		layout: 'Teddydekbed',
	},
	{
		path: '/shoptegoed',
		layout: 'Shoptegoed',
	},
	{
		path: '/parijs',
		layout: 'Parijs',
	},
	{
		path: '/refreshbadkamer',
		layout: 'Refreshbadkamer',
	},
	{
		path: '/refresh1000',
		layout: 'Refresh1000',
	},
	{
		path: '/thank-you',
		layout: 'thankyou',
	},
];

export default routes;