import Repository from "./Repository";

const ADD_GELUKKIGE_WINNAARS_LEAD = "2350/leads";
const ADD_BOXSPRING_LEAD = "2375/leads"
const ADD_TEDDYDEKBED_LEAD = "/2372/leads"
const ADD_SHOPTEGOED_LEAD = "2370/leads";
const ADD_PARIJS_LEAD = "2378/leads"
const ADD_REFRESHBADKAMER_LEAD = "2367/leads";

export default {
    addGelukkigeWinnaarsLead(payload) {
        return Repository.post(`${ADD_GELUKKIGE_WINNAARS_LEAD}`, payload);
    },
    addBoxspringLead(payload) {
        return Repository.post(`${ADD_BOXSPRING_LEAD}`, payload);
    },
    addTeddydekbedLead(payload) {
        return Repository.post(`${ADD_TEDDYDEKBED_LEAD}`, payload);
    },
    addShoptegoedLead(payload) {
        return Repository.post(`${ADD_SHOPTEGOED_LEAD}`, payload);
    },
    addParijsLead(payload) {
        return Repository.post(`${ADD_PARIJS_LEAD}`, payload);
    },
    addRefreshbadkamerLead(payload) {
        return Repository.post(`${ADD_REFRESHBADKAMER_LEAD}`, payload);
    },
};