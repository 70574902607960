import { Row, Col, Container } from 'reactstrap';
import classes from './Features.module.css';
import Feature_1 from '../../assets/svg/Feature_1.svg';
import Feature_2 from '../../assets/svg/Feature_2.svg';
import Feature_3 from '../../assets/svg/Feature_3.svg';
import Feature_4 from '../../assets/svg/Feature_4.svg';

export default function Features({ styling = { marginTop: '5rem' } }) {
  const features = [
    {
      icon: Feature_1,
      title: 'Win Unieke Prijzen',
      desc: 'Doe mee met opwindende prijsacties voor de kans op exclusieve beloningen.',
    },
    {
      icon: Feature_2,
      title: 'Exclusieve Kortingen',
      desc: 'Ontvang extreem hoge exclusieve  kortingen op geselecteerde topproducten.',
    },
    {
      icon: Feature_3,
      title: 'Eerlijk en Open',
      desc: 'Transparantie over de oorsprong van prijzen en hoe sponsoring werkt.',
    },

    {
      icon: Feature_4,
      title: 'Duidelijkheid Gegarandeerd',
      desc: 'Altijd duidelijkheid over de winacties en productkortingen.',
    }
  ]
  return (
    <div className={classes.featureSection} style={styling}>
      {/* De voordelen van Gelukkige Winnaars */}
      <h1>Jouw voordelen</h1>
      <Container className={classes.features}>
        <Row xs="1" sm="2" md="2" >
          {features.map((feature, index) => (
            <Col key={index} className={`p-4 ${classes.feature}`} lg="3">
              <img src={feature.icon} alt="icon" />
              <h6>{feature.title}</h6>
              <p>{feature.desc}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}