import Header from '../components/Header/Header';
import MainForm from '../components/Hero/Form/MainForm';
import StepCard from '../components/Hero/StepCard/StepCard';
import SimpleCards from '../components/Hero/SimpleCards/SimpleCards';
import { Row, Col, Container } from 'reactstrap';
import Features from '../components/Features/Features';
import Footer from '../components/Footer/Footer';
import Slide1 from '../assets/img/Shoptegoed/NewSlide.png';

const Shoptegoed = () => {
	const slides = [Slide1];
	const steps = [
		'Vul je gegevens in om kans te maken op de prijs en de korting te ontvangen',
		'Bevestig jouw deelname met de ontvangen e-mail in jouw inbox',
		'Na bevestiging ontvang jij de kortingscode en maak jij kans op € 250,00 euro shoptegoed!'
	]
	const title = 'Doe mee en win 1 van de 4 € 250,00 shoptegoedbonnen!';
	const formTitle = 'Laat je gegevens achter en maak direct kans op 1 van de 4 € 250,00 shoptegoedbonnen!';
	const desc = 'Elke deelnemer ontvangt gegarandeerd 10% korting voor Dekbed-Discounter!'
	return (
		<>
			<Header />
			<Container>
				<Row xs="1" sm="2" md="2">
					<Col className="p-4 p-sm-2" lg="3">
						<StepCard steps={steps} label={3} title={title} />
					</Col>
					<Col className="p-4 p-sm-2" lg="3">
						<SimpleCards slides={slides} label={2} />
					</Col>
					<Col className="p-4 p-sm-2" xs="12" sm="12" md="12" lg="6">
						<MainForm title={formTitle} desc={desc} />
					</Col>
				</Row>
			</Container>
			<Features />
			<Footer />
		</>
	);
};

export default Shoptegoed;
