import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import classes from "./Header.module.css";
import Logo from "../../assets/svg/Logo.svg";
import { useLocation } from "react-router-dom";

export default function Header() {
    const location = useLocation();
    const { pathname, state } = location;
    const targetDate = new Date("2024-05-31T00:00:00Z");

    const calculateCountdown = () => {
        const now = new Date();
        const timeDifference = targetDate - now;

        if (timeDifference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    const [countdown, setCountdown] = useState(calculateCountdown());

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(calculateCountdown());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <Container>
            <div className={classes.header}>
                <img src={Logo} alt="Logo" />
                {pathname === "/boxspring" || state?.from === "/boxspring" ? (
                    <div className={classes.dateSection}>
                        <p>Deze winactie loopt af op:</p>
                        <div className={classes.date}>
                            <span>31 oktober</span>
                        </div>
                    </div>
                ) : (
                    <div>
                        {pathname === "/parijs" || state?.from === "/parijs" ? (
                            <p>Deze winactie loopt af op:</p>
                        ) : (
                            <p>deze actie loopt af op:</p>
                        )}
                        <ul>
                            <li>
                                <span
                                    style={{
                                        width:
                                            (pathname === "/boxspring" ||
                                                state?.from === "/boxspring") &&
                                            "180px",
                                    }}
                                >
                                    {pathname === "/boxspring" ||
                                    state?.from === "/boxspring"
                                        ? "31 oktober"
                                        : pathname === "/refreshbadkamer" ||
                                          pathname === "/refresh1000" ||
                                          pathname === "/parijs" ||
                                          pathname === "/teddydekbed" ||
                                          state?.from === "/refreshbadkamer" ||
                                          state?.from === "/refresh1000" ||
                                          state?.from === "/parijs" ||
                                          state?.from === "/teddydekbed"
                                        ? "30 Sep"
                                        : "31 juli"}
                                </span>
                                2024
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </Container>
    );
}
