import { RepositoryFactory } from "../../repository/RepositoryFactory";

let Leads = RepositoryFactory.get("leads");

export const addGelukkigeWinnaarsLeadAction =
  (obj, onSuccess = () => { }, onError = (error, status) => { }) =>
    async (dispatch) => {
      dispatch(Loader(true));
      try {
        const { data } = await Leads.addGelukkigeWinnaarsLead(obj);
        if (data) {
          onSuccess();
          dispatch({
            type: "SUCCESS",
            payload: data?.profile?.id,
          });
          dispatch(Loader(false));
        }
      } catch (error) {
        dispatch(Loader(false));
        const status = error.response ? error.response.status : null;
        onError(error.message, status);
        console.log(error.message);
      }
    };

export const addBoxspringLeadAction =
  (obj, onSuccess = () => { }, onError = (error, status) => { }) =>
    async (dispatch) => {
      dispatch(Loader(true));
      try {
        const { data } = await Leads.addBoxspringLead(obj);
        if (data) {
          onSuccess(data?.profile?.id);
          dispatch({
            type: "SUCCESS",
            payload: data?.profile?.id,
          });
          dispatch(Loader(false));
        }
      } catch (error) {
        dispatch(Loader(false));
        const status = error.response ? error.response.status : null;
        onError(error.message, status);
        console.log(error.message);
      }
    };

export const addTeddydekbedLeadAction =
  (obj, onSuccess = () => { }, onError = (error, status) => { }) =>
    async (dispatch) => {
      dispatch(Loader(true));
      try {
        const { data } = await Leads.addTeddydekbedLead(obj);
        if (data) {
          onSuccess(data?.profile?.id);
          dispatch({
            type: "SUCCESS",
            payload: data?.profile?.id,
          });
          dispatch(Loader(false));
        }
      } catch (error) {
        dispatch(Loader(false));
        const status = error.response ? error.response.status : null;
        onError(error.message, status);
        console.log(error.message);
      }
    };

export const addShoptegoedLeadAction =
  (obj, onSuccess = () => { }, onError = (error, status) => { }) =>
    async (dispatch) => {
      dispatch(Loader(true));
      try {
        const { data } = await Leads.addShoptegoedLead(obj);
        if (data) {
          onSuccess(data?.profile?.id);
          dispatch({
            type: "SUCCESS",
            payload: data?.profile?.id,
          });
          dispatch(Loader(false));
        }
      } catch (error) {
        dispatch(Loader(false));
        const status = error.response ? error.response.status : null;
        onError(error.message, status);
        console.log(error.message);
      }
    };

export const addParijsLeadAction =
  (obj, onSuccess = () => { }, onError = (error, status) => { }) =>
    async (dispatch) => {
      dispatch(Loader(true));
      try {
        const { data } = await Leads.addParijsLead(obj);
        if (data) {
          onSuccess(data?.profile?.id);
          dispatch({
            type: "SUCCESS",
            payload: data?.profile?.id,
          });
          dispatch(Loader(false));
        }
      } catch (error) {
        dispatch(Loader(false));
        const status = error.response ? error.response.status : null;
        onError(error.message, status);
        console.log(error.message);
      }
    };

export const addRefreshbadkamerLeadAction =
  (obj, onSuccess = () => { }, onError = (error, status) => { }) =>
    async (dispatch) => {
      dispatch(Loader(true));
      try {
        const { data } = await Leads.addRefreshbadkamerLead(obj);
        if (data) {
          onSuccess(data?.profile?.id);
          dispatch({
            type: "SUCCESS",
            payload: data?.profile?.id,
          });
          dispatch(Loader(false));
        }
      } catch (error) {
        dispatch(Loader(false));
        const status = error.response ? error.response.status : null;
        onError(error.message, status);
        console.log(error.message);
      }
    };

export const Loader = (val) => async (dispatch) => {
  dispatch({
    type: "LOADER",
    payload: val,
  });
};