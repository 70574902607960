import classes from "./Modal.module.css"
import CloseIcon from "../../assets/svg/close-square.svg";
import Image from "../../assets/img/Card_Divider.png";
import { FormGroup, Input, Label } from "reactstrap";

const Modal = ({ isChecked, setIsChecked, closeModal }) => {
  return (
    <div className={classes.Modal} onClick={closeModal}>
      <div className={classes.Card} onClick={(e) => e.stopPropagation()}>
        <div className={classes.Content}>
          <img
            src={CloseIcon}
            className={classes.Close}
            onClick={closeModal}
          />
          <h2>Onze partner</h2>
          Hieronder vind je de partner van deze winactie. Geef toestemming om op de hoogte te blijven van de mooiste Dekbed-Discounter acties door de checkbox aan te vinken.
          <br />
          <br />
          Let op: zonder toestemming, kunnen wij de kortingsvoucher van 10% niet naar jou versturen.
          <div className={classes.Divider}></div>
          Dekbed Discounter
          <br />
          Gentseweg 5
          <br />
          2803PC Gouda
          <br />
          <br />
          <img width={130} src={Image} />
          <br />
          <br />
          <FormGroup check className="d-flex align-items-center">
            <Input
              type="checkbox"
              className="mb-2"
              onClick={() => setIsChecked(!isChecked)}
              checked={isChecked}
            />
            {' '}
            <Label check>
              Ja, ik geef Dekbed-Discounter toestemming mij op de hoogte te houden van de mooiste aanbiedingen en ontvang graag de 10% kortingsvoucher.
            </Label>
          </FormGroup>
        </div>
      </div>
    </div>
  );
}

export default Modal; 