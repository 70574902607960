import Header from '../components/Header/Header';
import MainForm from '../components/Hero/Form/MainForm';
import StepCard from '../components/Hero/StepCard/StepCard';
import SimpleCards from '../components/Hero/SimpleCards/SimpleCards';
import { Row, Col, Container } from 'reactstrap';
import Features from '../components/Features/Features';
import Footer from '../components/Footer/Footer';
import Slide1 from '../assets/img/Teddydekbed/Slide1.png';
import Slide2 from "../assets/img/Teddydekbed/Slide2.png";
import Slide3 from "../assets/img/Teddydekbed/Slide3.png";
import Slide4 from "../assets/img/Teddydekbed/Slide4.png";

const Teddydekbed = () => {
  const slides = [Slide1, Slide2, Slide3, Slide4];
  const steps = [
    'Vul je gegevens in om kans te maken op de prijs en de korting te ontvangen',
    'Bevestig jouw deelname met de ontvangen e-mail in jouw inbox',
    'Na bevestiging ontvang jij de kortingscode en maak jij kans op het teddy dekbedovertrek!'
  ]
  const title = 'Doe mee en win 1 van de 25 Teddy Dekbedovertrekken!'
  const formTitle = 'Laat je gegevens achter en maak direct kans op 1 van de 25 Teddy Dekbedovertrekken.'
  const desc = 'Elke deelnemer ontvangt gegarandeerd 10% korting voor Dekbed-Discounter!'
  return (
    <>
      <Header />
      <Container>
        <Row xs="1" sm="2" md="2">
          <Col className="p-4 p-sm-2" lg="3">
            <StepCard steps={steps} label={2} title={title} />
          </Col>
          <Col className="p-4 p-sm-2" lg="3">
            <SimpleCards slides={slides} label={1} />
          </Col>
          <Col className="p-4 p-sm-2" xs="12" sm="12" md="12" lg="6">
            <MainForm title={formTitle} desc={desc} />
          </Col>
        </Row>
      </Container>
      <Features />
      <Footer />

      {/* {props.children} */}
      {/* <footer>Main Footer</footer> */}
    </>
  );
};

export default Teddydekbed;
