import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header/Header';
import StepCard from '../components/Hero/StepCard/StepCard';
import { Row, Col, Container, Button } from 'reactstrap';
import Features from '../components/Features/Features';
import Footer from '../components/Footer/Footer';
import Slide1 from '../assets/img/Refreshbadkamer/Slide5.png';
import Slide2 from "../assets/img/Refreshbadkamer/Slide2.png";
import Slide3 from "../assets/img/Refreshbadkamer/Slide3.png";
import Slide4 from "../assets/img/Refreshbadkamer/Slide1.png";
import { Form, FormGroup, FormText, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import formClasses from '../components/Hero/Form/MainForm.module.css';
import cardClasses from '../components/Hero/SimpleCards/SimpleCards.module.css';
import ReviewsSVG from "../assets/img/Refreshbadkamer/review.png";

import { HiArrowSmallRight } from 'react-icons/hi2';
import { FaRegUserCircle } from 'react-icons/fa';
import { HiOutlineMail } from "react-icons/hi";
import { IoMdPhonePortrait } from "react-icons/io";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { addGelukkigeWinnaarsLeadAction, addRefreshbadkamerLeadAction } from '../store/actions/Action';
import { useDispatch, useSelector } from 'react-redux';

import Card_Divider from '../assets/svg/Refresh_Divider.svg'
import Arrow from '../assets/svg/RB_Arrow.svg'
import CardLabel from '../assets/svg/Label.svg';
import PriceLabel from '../assets/svg/PriceLabel4.svg';
import ImgLabel from '../assets/svg/Img_Label.svg';
import { HiArrowSmallUp } from 'react-icons/hi2';
import CTA1 from "../assets/img/Refreshbadkamer/cta1.png";
import CTA2 from "../assets/img/Refreshbadkamer/cta2.png";

const Refreshbadkamer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Loading } = useSelector(state => state.reducer);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);
  const formRef = useRef(null);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [details, setDetails] = useState({
    publisher_id: "",
    site_subid: "",
  });
  const nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneRegex = /^(0|\+31|31|0031|00 31)([-\s]?\d[-\s]?){9}$/;
  const slides = [Slide1, Slide2, Slide3, Slide4];
  const title = 'Doe mee en win een badkamer-renovatie in 1 dag';
  const [error, setError] = useState({
    fNameError: false,
    lNameError: false,
    emailError: false,
    phoneError: false,
  });
  const steps = [
    'Vul je gegevens in om kans te maken op de prijs en de korting te ontvangen',
    'Bevestig jouw deelname met de ontvangen e-mail in jouw inbox',
    'Na bevestiging ontvang jij de kortingscode en maak jij kans op de gratis badkamer!'
  ]
  const refreshFeatures = [
    { title: 'Wij komen bij je langs voor een intake' },
    { title: 'Je krijgt een heldere totaalprijs + 3D model' },
    { title: 'Een nieuwe badkamer in één dag' },
    { title: 'Genieten van je nieuwe douche' }
  ]

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneNumberWithoutPunctuation = phone
      .replace(/[\s-]/g, "")
      .replace(/^00(\d+)$/, "$1");
    let obj = {
      language: "nl_NL",
      site_custom_url: `https://gelukkigewinnaars.nl/refreshbadkamer?clickid=${details?.site_subid}&site=${details?.publisher_id}`,
      site_custom_name: "refresh_gratis_badkamer",
      publisher_id: details?.publisher_id,
      site_subid: details?.site_subid,
      firstname: firstName,
      lastname: lastName,
      email: email,
      phone_number: phoneNumberWithoutPunctuation,
    };

    // dispatch(
    //   addRefreshbadkamerLeadAction(obj, (profileId) => {
    //     const link = "https://republish.gelukkigewinnaars.nl/m/6276/9009a6a95374/?event=7341&unique_conversion_id=";
    //     dispatch(
    //       addGelukkigeWinnaarsLeadAction({ ...obj, answers: [5107] },
    //         () => {
    //           setEmail("");
    //           setFirstName("");
    //           setLastName("");
    //           setPhone("");
    //           history.push("/thank-you", { profileId, link, from: "/refreshbadkamer" });
    //         }, (error, status) => {
    //           if (status === 409) {
    //             history.push("/thank-you", { profileId, link, from: "/refreshbadkamer" });
    //           }
    //         }
    //       )
    //     );
    //   }, (error, status) => {
    //     if (status === 409) {
    //       history.push("/thank-you", { profileId: '', link: '', from: "/refreshbadkamer" });
    //     }
    //     if (status === 400) {
    //       setError((prevError) => ({ ...prevError, phoneError: true }));
    //     }
    //   }
    //   ));


    dispatch(
      addGelukkigeWinnaarsLeadAction({ ...obj, answers: [5107, 5157] },
        () => {
          // setEmail("");
          // setFirstName("");
          // setLastName("");
          // setPhone("");
          // history.push("/thank-you", { profileId, link, from: "/refreshbadkamer" });
        },
        (error, status) => {
          if (status === 409) {
            // history.push("/thank-you", { profileId, link, from: "/refreshbadkamer" });
          }
        }
      )
    );

    dispatch(
      addRefreshbadkamerLeadAction({ ...obj, answers: [5148] },
        (profileId) => {
          const link = "https://republish.gelukkigewinnaars.nl/m/6276/9009a6a95374/?event=7341&unique_conversion_id=";
          setEmail("");
          setFirstName("");
          setLastName("");
          setPhone("");
          history.push("/thank-you", { profileId, link, from: "/refreshbadkamer" });
        }, 
        (error, status) => {
          if (status === 409) {
            history.push("/thank-you", { profileId: '', link: '', from: "/refreshbadkamer" });
          }
          if (status === 400) {
            setError((prevError) => ({ ...prevError, phoneError: true }));
          }
        }
      )
    );


  };

  useEffect(() => {
    const query = history?.location?.search;
    if (query) {
      const site_subid = new URLSearchParams(query).get("clickid");
      const publisher_id = new URLSearchParams(query).get("site");
      setDetails((prev) => ({ ...prev, site_subid, publisher_id }));
    }
  }, []);

  useEffect(() => {
    const handleSwipe = () => {
      if (touchStartX.current !== null && touchEndX.current !== null) {
        const deltaX = touchEndX.current - touchStartX.current;
        if (deltaX > 50) {
          const prevSlide = (currentSlide === 0) ? slides.length - 1 : currentSlide - 1;
          setCurrentSlide(prevSlide);
        } else if (deltaX < -50) {
          const nextSlide = (currentSlide + 1) % slides.length;
          setCurrentSlide(nextSlide);
        }
        touchStartX.current = null;
        touchEndX.current = null;
      }
    };

    const handleTouchStart = (e) => {
      touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
      touchEndX.current = e.touches[0].clientX;
    };

    window.addEventListener('touchend', handleSwipe);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      window.removeEventListener('touchend', handleSwipe);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [currentSlide, slides.length]);

  useEffect(() => {
    const timer = setInterval(() => {
      const nextSlide = (currentSlide + 1) % slides.length;
      setCurrentSlide(nextSlide);
    }, 3000);

    return () => clearInterval(timer);
  }, [currentSlide, slides.length]);

  return (
    <div className='Refreshbadkamer'>
      <Header />
      <Container className='mb-5'>
        <Row xs="1" sm="2" md="2">
          <Col className="p-4 p-sm-2" lg="3">
            <StepCard steps={steps} label={5} title={title} />
          </Col>
          <Col className="p-4 p-sm-2" lg="4">
            <div className={cardClasses.simpleCards}>
              <div className="d-inline-block position-relative">
                {(currentSlide === 0 && (
                  <div className='label-section'>
                    <span className='star'>*</span>
                    <img
                      className='slider-label'
                      src={PriceLabel}
                      alt='price-tag'
                    />
                  </div>
                ))}
                <div className={cardClasses.dots}>
                  {slides.map((slide, index) => (
                    <span
                      key={index}
                      className={`${cardClasses.dot} ${currentSlide === index ? cardClasses.active : ''}`}
                      onClick={() => setCurrentSlide(index)}
                    ></span>
                  ))}
                </div>
                <img
                  src={slides[currentSlide]}
                  className={`${cardClasses.slider} refresh-slider`}
                  alt='slider'
                />
              </div>
              <div className="divider">
                <img src={Card_Divider} alt='divider' />
              </div>
              <div className="card border-0 d-inline-block position-relative">
                <img className="label" src={CardLabel} alt='slider' />
                <img className='arrow' src={Arrow} alt='arrow' />
                <h6>
                  Elke deelnemer <span>ontvangt € 1.000,00 korting</span> bij Refresh Bathrooms.
                  <img className='reviews mt-3' src={ReviewsSVG} alt="reviews" />
                </h6>
              </div>
            </div>
          </Col>
          <Col className="p-4 p-sm-2" xs="12" sm="12" md="12" lg="5">
            <div ref={formRef}></div>
            <Form
              onSubmit={handleSubmit}
              className={formClasses.mainForm}
              style={{ padding: '25px 30px' }}
            >
              <FormText className={formClasses.title}>
                <span>Ja, ik wil kans maken op de gratis badkamer renovatie: </span>
                en ontvang €&nbsp;1.000,00 korting bij Refresh Bathrooms<br />
                <p>(Binnen 7 dagen na de actieperiode wordt de winnaar bekendgemaakt)</p>
              </FormText>
              <FormGroup>
                <Label for="first-name">Voornaam</Label>
                <div>
                  <span className={formClasses.icon}><FaRegUserCircle /></span>
                  <Input
                    id="first-name"
                    name="first-name"
                    placeholder="Jan"
                    type="text"
                    value={firstName}
                    invalid={error.fNameError}
                    required
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setFirstName(inputValue);
                      setError({
                        ...error,
                        fNameError: !nameRegex.test(inputValue),
                      });
                    }}
                  />
                </div>
                {error?.fNameError && (
                  <FormFeedback style={{ color: "red" }}>
                    Voer een geldige voornaam in
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="last-name">Achternaam</Label>
                <div>
                  <span className={formClasses.icon}><FaRegUserCircle /></span>
                  <Input
                    id="last-name"
                    name="last-name"
                    placeholder="de Groot"
                    type="text"
                    value={lastName}
                    required
                    invalid={error?.lNameError}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setLastName(inputValue);
                      setError({
                        ...error,
                        lNameError: !nameRegex.test(inputValue),
                      });
                    }}
                  />
                </div>
                {error?.lNameError && (
                  <FormFeedback style={{ color: "red" }}>
                    Voer een geldige achternaam in
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="email">E-mailadres</Label>
                <div>
                  <span className={formClasses.icon}><HiOutlineMail /></span>
                  <Input
                    id="email"
                    name="email"
                    placeholder="jan@degroot.nl"
                    type="email"
                    value={email}
                    required
                    invalid={error?.emailError}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setEmail(inputValue);
                      setError({
                        ...error,
                        emailError: !emailRegex.test(inputValue),
                      });
                    }}
                  />
                </div>
                {error?.emailError && (
                  <FormFeedback style={{ color: "red" }}>
                    Voer een geldige e-mail in
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="number">Telefoonnnummer</Label>
                <div>
                  <span className={formClasses.icon}><IoMdPhonePortrait /></span>
                  <Input
                    id="number"
                    name="number"
                    placeholder="+31611223344"
                    type="text"
                    value={phone}
                    required
                    invalid={error?.phoneError}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setPhone(inputValue);
                      setError({
                        ...error,
                        phoneError: !phoneRegex.test(inputValue),
                      });
                    }}
                  />
                </div>
                {error?.phoneError && (
                  <FormFeedback style={{ color: "red" }}>
                    Voer een geldige telefoonnnummer in
                  </FormFeedback>
                )}
              </FormGroup>
              <button
                type="submit"
                disabled={Loading || error?.fNameError || error?.lNameError || error?.emailError || error?.phoneError}
              >
                {Loading ? <Spinner size="sm" /> :
                  <>
                    Doe nu mee aan de winactie
                    <HiArrowSmallRight />
                  </>
                }
              </button>
              <FormText className={formClasses.formFooter}>
                <p className='mb-0'>Met jouw gegevens gaan wij zorgvuldig om. Door deelname ga je er mee akkoord dat Gelukkige Winnaars je op de hoogte houdt van toekomstige acties. Daarnaast mag Refresh Bathrooms je 1 keer telefonisch benaderen om eventuele wensen over jouw toekomstige badkamer te bespreken.</p>
              </FormText>
              {/* <div className='share'>
                <img src={ShareSVG} alt="..." />
                <a className='share-link' href="">Delen</a>
              </div> */}
            </Form>
          </Col>
        </Row>
      </Container>
      <div className='cta'>
        <Container>
          <Row className='px-sm-2 d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between'>
            <div className='text-section'>
              <h2>Badkamer renovatie in één dag</h2>
              <p>
                <p>
                  Douche jij nog in bad? Heb je een douchebak met een hoge opstap? Of overweeg je een verbouwing van je totale badkamer?
                </p>
                <span>
                  Het was vroeger heel normaal om een badkamer met een bad/douche combinatie in te richten.{" "}
                </span>
                Inmiddels kiezen steeds meer mensen in plaats daarvan voor een mooie inloopdouche.{" "}
                <span>
                  Dat ziet er moderner uit, is een stuk praktischer en verkleint het risico op valpartijen. Zo kan je badkamer weer jaren mee. Ook als je een dagje ouder wordt.
                </span>
              </p>
              <button
                onClick={scrollToForm}
                className='mt-5'
              >
                Win een badkamer renovatie in één dag
                <HiArrowSmallUp />
              </button>
            </div>
            <div className='img-section'>
              <div className='position-relative'>
                <label className='top-label'>Voor de renovatie</label>
                <img src={CTA1} />
              </div>
              <div className='position-relative'>
                <img className='center-label' src={ImgLabel} />
                <label className='bottom-label'>Na de renovatie</label>
                <img src={CTA2} />
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className='refresh-features'>
        <Container>
          <Row>
            <h1>Zo werkt een badkamer renovatie bij <span>Refresh</span> Bathrooms</h1>
            <Row xs="1" sm="2" md="4" className='section'>
              {refreshFeatures.map((feature, index) => (
                <Col key={index} className='feature'>
                  <div className='icon'>{index + 1}</div>
                  <p>{feature.title}</p>
                </Col>
              ))}
            </Row>
          </Row>
        </Container>
      </div>
      <Features />
      <Footer />
    </div>
  );
};

export default Refreshbadkamer;