import axios from "axios";
const baseDomain = "https://leadgen.republish.nl/api/sponsors/";

const baseURL = `${baseDomain}`;

export default axios.create({
    baseURL,
    headers: {
        Authorization:
            "Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=",
            
    },
});