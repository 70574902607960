import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import Logo from '../../assets/svg/Logo.svg';
import classes from './Footer.module.css';
import { db } from '../../config/firebase';
import { doc, onSnapshot } from "firebase/firestore";
import { useLocation } from 'react-router-dom';

export default function Footer({ styling = {} }) {
  const location = useLocation();
  const { pathname, state } = location;
  const [privacyModal, setPrivacyModal] = useState(false);
  const [privacyContent, setPrivacyContent] = useState(``);
  const [voorwaardenModal, setVoorwaardenModal] = useState(false);
  const [voorwaardenContent, setVoorwaardenContent] = useState(``);

  useEffect(() => {
    const fetchPrivacyData = async () => {
      try {
        const docRef = doc(db, "GelukkigeWinnaars", "privacy");
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
          if (docSnap.exists()) {
            setPrivacyContent(docSnap.data().privacy);
          } else {
            console.log("No such document!");
          }
        });
        return unsubscribe;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchVoorwaardenData = async () => {
      try {
        let docRef = "";
        if (pathname === '/shoptegoed' || state?.from === '/shoptegoed') {
          const url = 'https://leadgen.republish.nl/api/content/algemenevoorwaarden-gelukkigewinnaars-1';
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.text();
          setVoorwaardenContent(data);
          return
        } else if (pathname === '/parijs' || state?.from === '/parijs') {
          const url = 'https://leadgen.republish.nl/api/content/algemenevoorwaarden-gelukkigewinnaars-2';
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.text();
          setVoorwaardenContent(data);
          return
        } else if (pathname === '/refreshbadkamer' || pathname === '/refresh1000' || state?.from === '/refreshbadkamer' || state?.from === '/refresh1000') {
          docRef = doc(db, "GelukkigeWinnaars", "refreshbadkamer");
        } else {
          docRef = doc(db, "GelukkigeWinnaars", "voorwaarden");
        }
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
          if (docSnap.exists()) {
            setVoorwaardenContent(docSnap.data().voorwaarden);
          } else {
            console.log("No such document!");
          }
        });
        return unsubscribe;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const unsubscribeFunctions = [fetchPrivacyData(), fetchVoorwaardenData()];

    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      });
    };
  }, [pathname, state]);


  const togglePrivacyModal = () => {
    setPrivacyModal(!privacyModal);
  };

  const toggleVoorwaardenModal = () => {
    setVoorwaardenModal(!voorwaardenModal);
  };

  return (
    <Container>
      <div className={classes.Footer} style={styling}>
        <img src={Logo} alt='Logo' />
        <p>Bekijk de <span onClick={togglePrivacyModal}>Privacy</span> & <span onClick={toggleVoorwaardenModal}>voorwaarden</span> van deze actie.</p>
        {(pathname === '/refreshbadkamer' || state?.from === '/refreshbadkamer') && (
          <p>* {" "} Dit is een gemiddeld bedrag. De actie gaat om een gratis badkamer. De waarde is hierbij niet relevant.</p>
        )}
        {(pathname === '/refresh1000' || state?.from === '/refresh1000') && (
          <p>* {" "} De waarde van de te winnen droom badkamer is maximaal €15.000,-</p>
        )}
      </div>
      {privacyModal && (
        <div className={classes.modalBackdrop} onClick={togglePrivacyModal}>
          <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
            <h4>Privacy beleid</h4>
            <div className={classes.privacyText}>
              <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
            </div>
            <div className={classes.privacyFooter}>
              <button onClick={() => setPrivacyModal(false)}>OK</button>
            </div>
          </div>
        </div>
      )}
      {voorwaardenModal && (
        <div className={classes.modalBackdrop} onClick={toggleVoorwaardenModal}>
          <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
            <h4>Algemene voorwaarden</h4>
            <div className={classes.privacyText}>
              <div dangerouslySetInnerHTML={{ __html: voorwaardenContent }} />
            </div>
            <div className={classes.privacyFooter}>
              <button onClick={() => setVoorwaardenModal(false)}>OK</button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}
