import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import routes from "./routes";
import store from "./store";

import Boxspring from "./layout/Boxspring";
import Teddydekbed from "./layout/Teddydekbed";
import Shoptegoed from "./layout/Shoptegoed";
import Parijs from "./layout/Parijs";
import Refreshbadkamer from "./layout/Refreshbadkamer";
import Refresh1000 from "./layout/Refresh1000";
import Thanks from "./layout/ThankYou";

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            {routes.map((route) => {
              switch (route.layout) {
                case "Boxspring":
                  return (
                    <Route key={route} exact path={route.path}>
                      <Boxspring>
                        <route.component />
                      </Boxspring>
                    </Route>
                  );
                case "Teddydekbed":
                  return (
                    <Route key={route} exact path={route.path}>
                      <Teddydekbed>
                        <route.component />
                      </Teddydekbed>
                    </Route>
                  );
                case "Shoptegoed":
                  return (
                    <Route key={route} exact path={route.path}>
                      <Shoptegoed>
                        <route.component />
                      </Shoptegoed>
                    </Route>
                  );
                case "Parijs":
                  return (
                    <Route key={route} exact path={route.path}>
                      <Parijs>
                        <route.component />
                      </Parijs>
                    </Route>
                  );
                case "Refreshbadkamer":
                  return (
                    <Route key={route} exact path={route.path}>
                      <Refreshbadkamer>
                        <route.component />
                      </Refreshbadkamer>
                    </Route>
                  );
                  case "Refresh1000":
                    return (
                      <Route key={route} exact path={route.path}>
                        <Refresh1000>
                          <route.component />
                        </Refresh1000>
                      </Route>
                    );
                case "thankyou":
                  return (
                    <Route key={route} exact path={route.path}>
                      <Thanks>
                        <route.component />
                      </Thanks>
                    </Route>
                  );
              }
            })}
            <Redirect to="/boxspring" />
          </Switch>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
