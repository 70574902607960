import { Row, Col, Container } from 'reactstrap';
import classes from './Steps.module.css';
import Icon from "../../assets/svg/Leaf.svg";

export default function Steps({ steps }) {
  return (
    <div className={classes.featureSection}>
      <h1>Hoe werkt het?</h1>
      <Container>
        <Row className={classes.features}>
          <div className={classes.line}></div>
          {steps?.map((step, index) => (
            <Col key={index} className={classes.feature}>
              <img width={64} height={64} src={Icon} alt="icon" />
              <h6>Stap {index + 1}</h6>
              <p>{step}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}