import { Container } from "reactstrap"
import classes from './ThankYou.module.css';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { useLocation } from 'react-router-dom';

export default function ThankYou() {
  const location = useLocation();
  const { profileId, link, isChecked = true } = location.state;
  return (
    <>
      <Header />
      <div className={classes.thanks}>
        <Container>
          {profileId && link && isChecked && (
            <img referrerpolicy="no-referrer-when-downgrade" src={link + profileId} />
          )}
          <div className={classes.card}>
            <h2>Bedankt voor je inschrijving bij de winactie! Bevestig je deelname via je e-mail en wie weet ben jij de Gelukkige Winnaar!</h2>
          </div>
        </Container>
      </div >
      <Footer />
    </>
  )
}