import React, { useState, useEffect } from 'react';
import classes from './MainForm.module.css';
import { Form, FormGroup, FormText, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import { HiArrowSmallRight } from 'react-icons/hi2';
import { FaRegUserCircle } from 'react-icons/fa';
import { HiOutlineMail } from "react-icons/hi";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  addGelukkigeWinnaarsLeadAction,
  addBoxspringLeadAction,
  addTeddydekbedLeadAction,
  addShoptegoedLeadAction,
  addParijsLeadAction,
} from '../../../store/actions/Action';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const MainForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { Loading } = useSelector(state => state.reducer);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [details, setDetails] = useState({
    publisher_id: "",
    site_subid: "",
  });
  const nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [error, setError] = useState({
    fNameError: false,
    lNameError: false,
    emailError: false,
    phoneError: false,
  });

  const determineSite = () => {
    switch (pathname) {
      case '/shoptegoed':
        return {
          name: 'dbd_250shoptegoed',
          url: 'https://gelukkigewinnaars.nl/shoptegoed',
        }
      case '/teddydekbed':
        return {
          name: 'dbd_teddydekbed',
          url: 'https://gelukkigewinnaars.nl/teddydekbed',
        }
      case '/parijs':
        return {
          name: 'dbd_parijs',
          url: 'https://gelukkigewinnaars.nl/parijs',
        }
      default:
        return {
          name: 'dbd_boxspring',
          url: 'https://gelukkigewinnaars.nl/boxspring',
        }
    }
  };

  const determineLink = () => {
    switch (pathname) {
      case '/shoptegoed':
        return "https://republish.gelukkigewinnaars.nl/m/6282/0b974a6bb5ea/?event=7347&unique_conversion_id=";
      case '/teddydekbed':
        return "https://republish.gelukkigewinnaars.nl/m/6279/40866c8946f5/?event=7344&unique_conversion_id=";
      case '/parijs':
        return "https://republish.gelukkigewinnaars.nl/m/6285/3d218ee89193/?event=7350&unique_conversion_id=";
      default:
        return "https://republish.gelukkigewinnaars.nl/m/6232/ae1261f8c871/?event=7285&unique_conversion_id=";
    }
  };

  const determineAction = () => {
    switch (pathname) {
      case '/shoptegoed':
        return addShoptegoedLeadAction;
      case '/parijs':
        return addParijsLeadAction;
      case '/teddydekbed':
        return addTeddydekbedLeadAction;
      default:
        return addBoxspringLeadAction;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const siteCustom = determineSite();
    let obj = {
      language: "nl_NL",
      site_custom_url: `${siteCustom?.url}?clickid=${details?.site_subid}&site=${details?.publisher_id}`,
      site_custom_name: siteCustom?.name,
      publisher_id: details?.publisher_id,
      site_subid: details?.site_subid,
      firstname: firstName,
      lastname: lastName,
      email: email,
    };
    const action = determineAction();

    dispatch(
      addGelukkigeWinnaarsLeadAction({ ...obj, answers: [5107, 5154] },
        () => {
          // setEmail("");
          // setFirstName("");
          // setLastName("");
          // const link = determineLink();
          // history.push('/thank-you', { profileId, link });
        },
        (error, status) => {
          if (status === 409) {
            // history.push("/thank-you", { profileId: '', link: '' });
          }
        }
      )
    );

    dispatch(
      action({ ...obj, answers: [5151] },
        (profileId) => {
          const link = determineLink();
          history.push('/thank-you', { profileId, link, from: pathname });
        },
        (error, status) => {
          if (status === 409) {
            history.push("/thank-you", { profileId: '', link: '', from: pathname });
          }
        }
      )
    );
  };

  useEffect(() => {
    const query = history?.location?.search;
    if (query) {
      const site_subid = new URLSearchParams(query).get("clickid");
      const publisher_id = new URLSearchParams(query).get("site");
      setDetails((prev) => ({ ...prev, site_subid, publisher_id }));
    }
  }, []);

  return (
    <Form className={classes.mainForm} onSubmit={handleSubmit}>
      <FormText className={classes.title}>
        <span>{props.title}</span><br />
        {props.desc}<br />
        <p>(Binnen 7 dagen na de actieperiode wordt de winnaar bekendgemaakt)</p>
      </FormText>
      <FormGroup>
        <Label for="first-name">Voornaam</Label>
        <div>
          <span className={classes.icon}><FaRegUserCircle /></span>
          <Input
            id="first-name"
            name="first-name"
            placeholder="Jan"
            type="text"
            value={firstName}
            invalid={error.fNameError}
            required
            onChange={(e) => {
              const inputValue = e.target.value;
              setFirstName(inputValue);
              setError({
                ...error,
                fNameError: !nameRegex.test(inputValue),
              });
            }}
          />
        </div>
        {error?.fNameError && (
          <FormFeedback style={{ color: "red" }}>
            Voer een geldige voornaam in
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="last-name">Achternaam</Label>
        <div>
          <span className={classes.icon}><FaRegUserCircle /></span>
          <Input
            id="last-name"
            name="last-name"
            placeholder="de Groot"
            type="text"
            value={lastName}
            required
            invalid={error?.lNameError}
            onChange={(e) => {
              const inputValue = e.target.value;
              setLastName(inputValue);
              setError({
                ...error,
                lNameError: !nameRegex.test(inputValue),
              });
            }}
          />
        </div>
        {error?.lNameError && (
          <FormFeedback style={{ color: "red" }}>
            Voer een geldige achternaam in
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="email">E-mailadres</Label>
        <div>
          <span className={classes.icon}><HiOutlineMail /></span>
          <Input
            id="email"
            name="email"
            placeholder="jan@degroot.nl"
            type="email"
            value={email}
            required
            invalid={error?.emailError}
            onChange={(e) => {
              const inputValue = e.target.value;
              setEmail(inputValue);
              setError({
                ...error,
                emailError: !emailRegex.test(inputValue),
              });
            }}
          />
        </div>
        {error.emailError && (
          <FormFeedback style={{ color: "red" }}>
            Voer een geldige e-mail in
          </FormFeedback>
        )}
      </FormGroup>
      <button type="submit" disabled={Loading}>
        {Loading ? <Spinner size="sm" /> :
          <>
            Bevestig deelname
            <HiArrowSmallRight />
          </>
        }
      </button>
      <FormText className={classes.formFooter}>
        <p>Wij gaan zorgvuldig om met jouw gegevens. Gelukkige Winnaars en Dekbed-Discounter gebruiken jouw gegevens om de prijs en kortingsvoucher toe te sturen. Door op "Bevestig deelname" te klikken, ga je er mee akkoord dat Gelukkige Winnaars en Dekbed-Discounter je wekelijks via e-mail op de hoogte houden van de mooiste acties en aanbiedingen.</p>
      </FormText>
    </Form>
  );
}

export default MainForm;