// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import ReducerState from "./reducer";

export let rootReducer = combineReducers({
  authUser: authUserReducer,
  reducer: ReducerState,
});

export default rootReducer;
