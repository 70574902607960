const initialState = {
    profileID: null,
    Loading: false,
};

export default function ReducerState(state = initialState, { type, payload }) {
    switch (type) {
        case "SUCCESS":
            return {
                ...state,
                profileID: payload,
            };
        case "LOADER":
            return {
                ...state,
                Loading: payload,
            };
        default:
            return {
                ...state,
            };
    }
}