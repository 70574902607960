import classes from "./StepCard.module.css"
import Leaf from '../../../assets/svg/Leaf.svg'
import Leaf_2 from '../../../assets/svg/Leaf_2.svg'

const StepCard = (props) => {
  return (
    <div className={classes.stepCard}>
      <div className={classes.leafLabels}>
        <img style={{ width: '50px' }} src={Leaf_2} alt='slider' />
        <img style={{ width: '80px' }} src={Leaf} alt='slider' />
      </div>
      <div>
        {props?.label === 1 && <div className={classes.label}>t.w.v. € 299,00</div>}
        {props?.label === 3 && <div className={classes.label}>t.w.v. € 250,00</div>}
        {props?.label === 4 && <div className={classes.label}>t.w.v. € 1500,00</div>}
        {props?.label === 5 && <div className={classes.label}>t.w.v. € 15.000,-</div>}
        {props?.label === 6 && <div className={classes.refreshLabel}>Korting t.w.v. € 1.000,-</div>}
        <h2 style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }} className={`${props.label !== 2 && classes.parisResponsiveness} ${props.label === 6 && classes.refreshHeader}`}>
          {props.title}
        </h2>

        <p className={classes.question}>Hoe werkt het?</p>
      </div>
      <div className={classes.steps}>
        {props?.steps.map((step, index) => (
          <div key={index} className={classes.step}>
            <img src={Leaf} alt="Leaf" />
            <span>
              <h6>Stap {index + 1}</h6>
              <p>{step}</p>
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StepCard;