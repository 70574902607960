import { Row, Col, Container, Form, FormText, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap';
import Features from '../components/Features/Features';
import Footer from '../components/Footer/Footer';
import Slide1 from '../assets/img/Boxspring/Slide1.png';
import Slide2 from "../assets/img/Boxspring/Slide2.png";
import Slide3 from "../assets/img/Boxspring/Slide3.png";
import Slide4 from "../assets/img/Boxspring/Slide4.png";
import Steps from '../components/Steps/Steps';
import classes from "../assets/css/parijs.module.css";
import Leaf from '../assets/svg/Leaf.svg';
import Leaf_2 from '../assets/svg/Leaf_2.svg';
import label from '../assets/svg/Label.svg';
import Arrow from '../assets/svg/ParijsArrow.svg';
import Card_Divider from '../assets/svg/Card_Divider.svg'
import Card_Divider2 from '../assets/img/Card_Divider_2.png'
import HiArrowSmallRight from "../assets/svg/smallArrow.svg";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
	addBoxspringLeadAction,
	addGelukkigeWinnaarsLeadAction,
	addParijsLeadAction,
} from '../store/actions/Action';
import { FaRegUserCircle } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import Modal from '../components/Modal/Modal';
import Logo from '../assets/svg/Logo.svg';

const Parijs = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { pathname } = location;
	const { Loading } = useSelector(state => state.reducer);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [details, setDetails] = useState({
		publisher_id: "",
		site_subid: "",
	});
	const nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
	const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

	const slides = [Slide1, Slide2, Slide3, Slide4];
	const stepsRef = useRef(null);
	const formRef = useRef(null);

	const [currentSlide, setCurrentSlide] = useState(0);
	const touchStartX = useRef(null);
	const touchEndX = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [isChecked, setIsChecked] = useState(true);

	const [error, setError] = useState({
		fNameError: false,
		lNameError: false,
		emailError: false,
		phoneError: false,
	});

	const determineSite = () => {
		return {
			name: 'dbd_boxspring',
			url: 'https://gelukkigewinnaars.nl/boxspring',
		}
	};

	const determineLink = () => {
		return "https://republish.gelukkigewinnaars.nl/m/6232/ae1261f8c871/?event=7285&unique_conversion_id=";
	};

	const determineAction = () => {
		return addBoxspringLeadAction;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const siteCustom = determineSite();
		let obj = {
			language: "nl_NL",
			site_custom_url: `${siteCustom?.url}?clickid=${details?.site_subid}&site=${details?.publisher_id}`,
			site_custom_name: siteCustom?.name,
			publisher_id: details?.publisher_id,
			site_subid: details?.site_subid,
			firstname: firstName,
			lastname: lastName,
			email: email,
		};
		const action = determineAction();

		dispatch(
			addGelukkigeWinnaarsLeadAction({ ...obj, answers: [5107, 5154] },
				() => {
					// setEmail("");
					// setFirstName("");
					// setLastName("");
					// const link = determineLink();
					// history.push('/thank-you', { profileId, link });
				},
				(error, status) => {
					if (status === 409) {
						// history.push("/thank-you", { profileId: '', link: '' });
					}
				}
			)
		);

		dispatch(
			action({ ...obj, answers: [5151] },
				(profileId) => {
					const link = determineLink();
					history.push('/thank-you', { profileId, link, isChecked, from: pathname });
				},
				(error, status) => {
					if (status === 409) {
						history.push("/thank-you", { profileId: '', link: '', from: pathname });
					}
				}
			)
		);
	};

	useEffect(() => {
		const query = history?.location?.search;
		if (query) {
			const site_subid = new URLSearchParams(query).get("clickid");
			const publisher_id = new URLSearchParams(query).get("site");
			setDetails((prev) => ({ ...prev, site_subid, publisher_id }));
		}
	}, []);

	useEffect(() => {
		const handleSwipe = () => {
			if (touchStartX.current !== null && touchEndX.current !== null) {
				const deltaX = touchEndX.current - touchStartX.current;
				if (deltaX > 50) {
					const prevSlide = (currentSlide === 0) ? slides.length - 1 : currentSlide - 1;
					setCurrentSlide(prevSlide);
				} else if (deltaX < -50) {
					const nextSlide = (currentSlide + 1) % slides.length;
					setCurrentSlide(nextSlide);
				}
				touchStartX.current = null;
				touchEndX.current = null;
			}
		};

		const handleTouchStart = (e) => {
			touchStartX.current = e.touches[0].clientX;
		};

		const handleTouchMove = (e) => {
			touchEndX.current = e.touches[0].clientX;
		};

		window.addEventListener('touchend', handleSwipe);
		window.addEventListener('touchstart', handleTouchStart);
		window.addEventListener('touchmove', handleTouchMove);

		return () => {
			window.removeEventListener('touchend', handleSwipe);
			window.removeEventListener('touchstart', handleTouchStart);
			window.removeEventListener('touchmove', handleTouchMove);
		};
	}, [currentSlide, slides.length]);

	useEffect(() => {
		const timer = setInterval(() => {
			const nextSlide = (currentSlide + 1) % slides.length;
			setCurrentSlide(nextSlide);
		}, 3000);

		return () => clearInterval(timer);
	}, [currentSlide, slides.length]);

	return (
		<>
			<Container>
				<div className={classes.header}>
					<img src={Logo} alt='Logo' />
					<div className={classes.dateSection}>
						<p>Deze winactie loopt af op:</p>
						<div className={classes.date}>
							<span>31 oktober</span> | 2024
						</div>
					</div>
				</div>
			</Container>
			<div className={classes.spacing}>
				<br />
			</div>
			<Container>
				<Row xs="1" sm="2" md="2">
					<Col className={`p-4 p-sm-2 ${classes.mainSection}`} xs="12" sm="12" md="12" lg="7">
						<div className={classes.stepCard}>
							<div className={classes.leafLabels}>
								<img style={{ width: '60px' }} src={Leaf_2} alt='slider' />
								<img style={{ width: '90px' }} src={Leaf} alt='slider' />
							</div>
							<div>
								<div className={classes.label}>t.w.v. € 399,00</div>
								<h2>
									Win een Opberg-boxspring
								</h2>
								<div className='d-flex flex-column flex-sm-row justify-content-between align-items-sm-center'>
									<h3>Voor 2 personen</h3>
									<p
										onClick={() => {
											stepsRef.current.scrollIntoView({ behavior: 'smooth' });
										}}
										className={classes.question}>Hoe werkt het?</p>
								</div>
							</div>
						</div>
						<div className='position-relative d-none d-sm-block'>
							<img
								src={slides[currentSlide]}
								alt='slider'
								className={classes.slider}
							/>
							<div className={classes.dots}>
								{slides.map((_, index) => (
									<span
										key={index}
										className={`${classes.dot} ${currentSlide === index ? classes.active : ''}`}
										onClick={() => setCurrentSlide(index)}
									></span>
								))}
							</div>
						</div>
						<div className={classes.box}>
							<div className={classes.boxLogo}>
								Altijd prijs!
							</div>
							<div className='position-relative'>
								<img height={45} className='pr-3' src={Card_Divider2} />
								<img width={70} src={Arrow} className={classes.boxArrow} />
							</div>
							<div className={classes.boxText}>
								Ontvang na deelname <br />
								<span>10% korting</span>
							</div>
						</div>
					</Col>
					<Col className="px-4 p-sm-2" xs="12" sm="12" md="12" lg="5">
						<div ref={formRef}></div>
						<Form className={classes.mainForm} onSubmit={handleSubmit}>
							<FormText className={classes.title}>
								<span>Ja, ik wil kans maken op de Opbergboxspring!</span><br />
							</FormText>
							<FormGroup>
								<Label for="first-name">Voornaam</Label>
								<div>
									<span className={classes.icon}><FaRegUserCircle /></span>
									<Input
										id="first-name"
										name="first-name"
										placeholder="Jan"
										type="text"
										value={firstName}
										invalid={error.fNameError}
										required
										onChange={(e) => {
											const inputValue = e.target.value;
											setFirstName(inputValue);
											setError({
												...error,
												fNameError: !nameRegex.test(inputValue),
											});
										}}
									/>
								</div>
								{error?.fNameError && (
									<FormFeedback style={{ color: "red" }}>
										Voer een geldige voornaam in
									</FormFeedback>
								)}
							</FormGroup>
							<FormGroup>
								<Label for="last-name">Achternaam</Label>
								<div>
									<span className={classes.icon}><FaRegUserCircle /></span>
									<Input
										id="last-name"
										name="last-name"
										placeholder="de Groot"
										type="text"
										value={lastName}
										required
										invalid={error?.lNameError}
										onChange={(e) => {
											const inputValue = e.target.value;
											setLastName(inputValue);
											setError({
												...error,
												lNameError: !nameRegex.test(inputValue),
											});
										}}
									/>
								</div>
								{error?.lNameError && (
									<FormFeedback style={{ color: "red" }}>
										Voer een geldige achternaam in
									</FormFeedback>
								)}
							</FormGroup>
							<FormGroup>
								<Label for="email">E-mailadres</Label>
								<div>
									<span className={classes.icon}><HiOutlineMail /></span>
									<Input
										id="email"
										name="email"
										placeholder="jan@degroot.nl"
										type="email"
										value={email}
										required
										invalid={error?.emailError}
										onChange={(e) => {
											const inputValue = e.target.value;
											setEmail(inputValue);
											setError({
												...error,
												emailError: !emailRegex.test(inputValue),
											});
										}}
									/>
								</div>
								{error.emailError && (
									<FormFeedback style={{ color: "red" }}>
										Voer een geldige e-mail in
									</FormFeedback>
								)}
							</FormGroup>
							<button type="submit" disabled={Loading}>
								{Loading ? <Spinner size="sm" /> :
									<>
										Ik doe mee &nbsp;
										<img src={HiArrowSmallRight} />
									</>
								}
							</button>
							<FormText className={classes.formFooter}>
								<span>Binnen 7 dagen na de actieperiode worden winnaars gekozen.</span>
								<p>Wij gaan zorgvuldig om met jouw gegevens. Gelukkige Winnaars en onze
									<b
										style={{ fontWeight: 600, cursor: 'pointer' }}
										onClick={() => {
											if (!isModalOpened) {
												setIsChecked(false);
												setIsModalOpened(true);
											}
											setShowModal(true);
										}}
									>
										{" "}partner{" "}
									</b>
									gebruiken jouw gegevens om de prijs en kortingsvoucher toe te sturen. Door op "Bevestig deelname" te klikken, ga je er mee akkoord dat Gelukkige Winnaars en onze
									<b
										style={{ fontWeight: 600, cursor: 'pointer' }}
										onClick={() => {
											if (!isModalOpened) {
												setIsChecked(false);
												setIsModalOpened(true);
											}
											setShowModal(true);
										}}
									>
										{" "}partner{" "}
									</b>
									je wekelijks via e-mail op de hoogte houden van de mooiste acties en aanbiedingen.</p>
							</FormText>
						</Form>
					</Col>
					<Col className="d-sm-none px-4 p-sm-2 mt-4" xs="12" sm="12" md="12" lg="5">
						<div>
							<img
								src={slides[currentSlide]}
								alt='slider'
								className={classes.slider}
							/>
							<div className={classes.dots}>
								{slides.map((_, index) => (
									<span
										key={index}
										className={`${classes.dot} ${currentSlide === index ? classes.active : ''}`}
										onClick={() => setCurrentSlide(index)}
									></span>
								))}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
			<div ref={stepsRef}>
				<Steps
					steps={[
						'Vul je gegevens in om kans te maken op de prijs en de korting te ontvangen',
						'Bevestig jouw deelname met de ontvangen e-mail in jouw inbox',
						'Na bevestiging ontvang jij de kortingscode en maak jij kans op op de boxspring!'
					]}
				/>
			</div>
			<Features styling={{ backgroundColor: 'white' }} />
			<Footer styling={{ borderTop: '1px solid #00A963' }} />
			<div className={classes.spacing}>
				<br />
				<br />
			</div>
			{showModal && (
				<Modal
					isChecked={isChecked}
					setIsChecked={setIsChecked}
					closeModal={() => setShowModal(false)}
				/>
			)}
			<div className={classes.scrollToForm}>
				<button
					onClick={() => {
						formRef.current.scrollIntoView({ behavior: 'smooth' });
					}}
					className={classes.scrollBtn}
				>
					Ik doe mee &nbsp;
					<img src={HiArrowSmallRight} />
				</button>
			</div>
		</>
	);
};

export default Parijs;
