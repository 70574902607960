import React, { useState, useEffect, useRef } from 'react';
import classes from './SimpleCards.module.css';
import Card_Divider from "../../../assets/img/Card_Divider.png";
import Leaf from '../../../assets/svg/Leaf.svg';
import Leaf_2 from '../../../assets/svg/Leaf_2.svg';
import BoxspringLabel from '../../../assets/svg/BoxspringLabel.svg';
import PriceLabel_2 from '../../../assets/svg/Price_Label_2.svg';
import ParijsLabel from '../../../assets/svg/ParijsLabel.svg';
import Label from '../../../assets/svg/Label.svg';
import ShoptegoedLabel from '../../../assets/img/Shoptegoed/label.png';
import Arrow from '../../../assets/svg/Arrow.svg';
import TeddyLabel from '../../../assets/svg/25x.svg';

const SimpleCards = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  useEffect(() => {
    const handleSwipe = () => {
      if (touchStartX.current !== null && touchEndX.current !== null) {
        const deltaX = touchEndX.current - touchStartX.current;
        if (deltaX > 50) {
          const prevSlide = (currentSlide === 0) ? props.slides.length - 1 : currentSlide - 1;
          setCurrentSlide(prevSlide);
        } else if (deltaX < -50) {
          const nextSlide = (currentSlide + 1) % props.slides.length;
          setCurrentSlide(nextSlide);
        }
        touchStartX.current = null;
        touchEndX.current = null;
      }
    };

    const handleTouchStart = (e) => {
      touchStartX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
      touchEndX.current = e.touches[0].clientX;
    };

    window.addEventListener('touchend', handleSwipe);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      window.removeEventListener('touchend', handleSwipe);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [currentSlide, props.slides.length]);

  useEffect(() => {
    const timer = setInterval(() => {
      const nextSlide = (currentSlide + 1) % props.slides.length;
      setCurrentSlide(nextSlide);
    }, 3000);

    return () => clearInterval(timer);
  }, [currentSlide, props.slides.length]);

  return (
    <div className={classes.simpleCards}>
      <div className={classes.topArea}>
        <div className="d-inline-block position-relative">
          {(currentSlide === 0 && props.label === 0 && <img className={classes.sliderLabel} src={BoxspringLabel} alt='price-tag' />)}
          {(currentSlide === 0 && props.label === 1 && <img className={classes.teddyLabel} src={TeddyLabel} alt='25x' />)}
          {(currentSlide === 0 && props.label === 3 && <img className={classes.sliderLabel} src={ParijsLabel} alt='price-tag' />)}
          {props.label !== 2 && (
            <div className={classes.dots}>
              {props.slides.map((slide, index) => (
                <span
                  key={index}
                  className={`${classes.dot} ${currentSlide === index ? classes.active : ''}`}
                  onClick={() => setCurrentSlide(index)}
                ></span>
              ))}
            </div>
          )}
          {props.label === 2 ? (
            <>
              <div className={classes.shoptegoedSlide}>
                <img
                  className={classes.slide}
                  src={props.slides[currentSlide]}
                  alt='slider'
                />
                <div className={classes.Label}>
                  <img
                    src={ShoptegoedLabel}
                    alt='label'
                  />
                </div>
              </div>
            </>
          ) : (
            <img
              src={props.slides[currentSlide]}
              alt='slider'
              className={classes.slider}
            />
          )}
        </div>
        <div className={classes.Divider}>
          <img src={Card_Divider} alt='divider-text' />
          <div className={classes.leafLabels}>
            <img className={classes.leaf} src={Leaf} alt='leaf' />
            <img src={Leaf_2} alt='leaf' />
          </div>
          <div className={classes.Arrow}>
            <img src={Arrow} alt='arrow' />
          </div>
        </div>
      </div>
      <div className="d-inline-block position-relative">
        <img className={classes.label_2} src={Label} alt='slider' />
        <h5>Ontvang na deelname <span>10% korting</span> bij Dekbed-Discounter</h5>
      </div>
    </div>
  );
}

export default SimpleCards;
